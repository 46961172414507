import React from "react";
import { string, func } from "prop-types";
import Title from "../Base/Title";
import Image from "../../components/ImageQuerys/CaseStudiesImages";
import { dateRegExp, getMonthName } from "./helpers";
import "./resource-card.css";
import { resourceTags } from "../../constants";

const ResourceCard = (props) => {
  const {
    className,
    title,
    imageName,
    imageUrl,
    date,
    tag,
    industry,
    href,
    posterName,
    onClick,
    setVideoData,
  } = props;

  const dateArray = date?.match(dateRegExp)[0]?.split("-");
  const month = getMonthName(dateArray[1]);
  const day = dateArray[2];

  const handleClick = () => {
    onClick();

    if (tag === resourceTags.VIDEO_TESTIMONIAL) {
      setVideoData({
        title,
        videoId: href,
        posterName,
      });
      return true;
    }
    return href ? window.open(href, "_blank") : null;
  };

  return (
    <div
      className={`${className} r-card
      max-w-[350px] flex flex-col items-start w-full
      cursor-pointer
    `}
      role="button"
      onClick={handleClick}
    >
      <div
        className={`
        relative overflow-hidden
        max-w-[350px] max-h-[290px]
        min-w-[330px] md:min-w-[350px] min-h-[290px]
        w-full h-full rounded-lg
      `}
      >
        {imageUrl ? (
          <img
            className="max-w-[350px] max-h-[290px] w-full rounded-lg scale-150"
            src={imageUrl}
            alt="blog post"
            loading="lazy"
            style={{ height: "inherit", objectFit: "cover" }}
          />
        ) : (
          <Image imageName={imageName} className="overflow-hidden" />
        )}
        <div
          className={`
            absolute right-0 bottom-6
            text-white bg-primary py-4 px-6
            rounded-l-full
        `}
        >
          {tag}
        </div>
        {tag === "Webinars" && (
          <div className="absolute bottom-6 left-4 text-white text-[12px]">
            <div className="font-bold">SpotOn</div>
            <div>Webinar</div>
          </div>
        )}
        {tag === "Webinars" && (
          <div
            className={`
              absolute top-16 -right-7 
              transform -rotate-90 
              text-black bg-white font-bold
              p-2.5 rounded-t-md 
            `}
          >
            spoton.com
          </div>
        )}
      </div>

      <Title className="mt-4" level={4}>
        {title}
      </Title>

      {date && (
        <div className="-mt-4 mb-4 text-sm text-black-300 tracking-[-0.01em]">
          {month} {day}
        </div>
      )}

      <div className="text-black bg-black-800 rounded-lg py-2.5 px-4">
        {industry}
      </div>
    </div>
  );
};

ResourceCard.propTypes = {
  className: string,
  imageName: string,
  imageUrl: string,
  title: string.isRequired,
  date: string,
  tag: string.isRequired,
  industry: string.isRequired,
  href: string,
  posterName: string,
  onClick: func,
  setVideoData: func,
};

ResourceCard.defaultProps = {
  className: "",
  imageName: null,
  imageUrl: null,
  date: null,
  href: null,
  posterName: "",
  onClick: () => true,
  setVideoData: () => true,
};

export default ResourceCard;
