import {
  resourceCategories,
  resourceGoals,
  resourceIndustries,
  resourceTags,
} from "../../constants";

export const dateRegExp = /(\d)+-(\d+)-(\d)+/;

export const monthMap = {
  "01": "January",
  "02": "February",
  "03": "March",
  "04": "April",
  "05": "May",
  "06": "June",
  "07": "July",
  "08": "August",
  "09": "September",
  10: "October",
  11: "November",
  12: "December",
};

export const getMonthName = (month) => {
  if (!month) {
    return "";
  }

  return monthMap[month];
};

export const sortByDateDescending = (data) =>
  data.sort((a, b) => new Date(b.date) - new Date(a.date));

const filterByIndustry = (data = [], industry = "") => {
  if (!industry || industry === "All") {
    return data;
  }

  return data.filter((i) => i.industry === industry);
};

const filterByGoal = (data = [], goal = "") => {
  if (!goal || goal === "All") {
    return data;
  }

  return data.filter((i) => i.goals.includes(goal));
};

export function filterDataToRender(
  selectedPill,
  data,
  blogPosts,
  infographics,
  industryFilter,
  goalFilter
) {
  if (selectedPill === "all" || selectedPill === undefined) {
    const dataToRender = [
      ...filterByIndustry(
        filterByGoal(blogPosts, goalFilter),
        industryFilter
      ).slice(0, 3),
      ...filterByIndustry(
        filterByGoal(data[resourceCategories.VIDEOS], goalFilter),
        industryFilter
      ).slice(0, 3),
      ...filterByIndustry(
        filterByGoal(data[resourceCategories.CASE_STUDIES], goalFilter),
        industryFilter
      ).slice(0, 3),
      ...filterByIndustry(
        filterByGoal(data[resourceCategories.WHITE_PAPERS], goalFilter),
        industryFilter
      ).slice(0, 3),
      ...filterByIndustry(
        filterByGoal(data[resourceCategories.TOOLS], goalFilter),
        industryFilter
      ).slice(0, 3),
      ...filterByIndustry(
        filterByGoal(data[resourceCategories.WEBINARS], goalFilter),
        industryFilter
      ).slice(0, 3),
      ...filterByIndustry(
        filterByGoal(infographics, goalFilter),
        industryFilter
      ).slice(0, 3),
    ];
    return dataToRender;
  }

  if (selectedPill === resourceCategories.BLOG) {
    return goalFilter ? filterByGoal(blogPosts, goalFilter) : blogPosts;
  }

  if (selectedPill === resourceCategories.INFOGRAPHICS) {
    return filterByIndustry(
      filterByGoal(infographics, goalFilter),
      industryFilter
    );
  }

  if (selectedPill === resourceCategories.VIDEOS) {
    return filterByIndustry(
      filterByGoal(data[resourceCategories.VIDEOS], goalFilter),
      industryFilter
    );
  }

  if (selectedPill === resourceCategories.WHITE_PAPERS) {
    return filterByIndustry(
      filterByGoal(data[resourceCategories.WHITE_PAPERS], goalFilter),
      industryFilter
    );
  }

  if (selectedPill === resourceCategories.CASE_STUDIES) {
    return filterByIndustry(
      filterByGoal(data[resourceCategories.CASE_STUDIES], goalFilter),
      industryFilter
    );
  }

  if (selectedPill === resourceCategories.TOOLS) {
    return filterByIndustry(
      filterByGoal(data[resourceCategories.TOOLS], goalFilter),
      industryFilter
    );
  }

  if (selectedPill === resourceCategories.WEBINARS) {
    return filterByIndustry(
      filterByGoal(data[resourceCategories.WEBINARS], goalFilter),
      industryFilter
    );
  }

  return [];
}

export function normalizeBlogData(post) {
  if (!post) {
    return {};
  }

  const goals = post.tags.map((i) => i.meta_title).filter((i) => i !== null);

  return {
    id: post.id + Date.now().toString(),
    title: post.title,
    imageUrl: post.feature_image,
    tag: "Blog",
    industry: post.primary_tag?.name,
    date: post.published_at,
    href: post.url,
    goals,
  };
}

export function normalizeInfographicData(post) {
  if (!post) {
    return {};
  }

  const goals = post.tags.map((i) => i.meta_title).filter((i) => i !== null);

  return {
    id: post.id + Date.now().toString(),
    title: post.title,
    imageUrl: post.feature_image,
    tag: post.primary_tag?.name,
    industry: post.tags[1]?.name,
    date: post.published_at,
    href: post.url,
    goals,
  };
}

export const mapSpotonProductToHref = (product = "") => {};
