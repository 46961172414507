import React from "react";
import { string, object, bool, oneOf, oneOfType } from "prop-types";

const getTitle = (level, styles, text) => {
  if (level === 1) {
    return <h1 className={`text-h1-small lg:text-h1 ${styles}`}>{text}</h1>;
  }

  if (level === 2) {
    return <h2 className={`text-h2-small lg:text-h2 ${styles}`}>{text}</h2>;
  }

  if (level === 3) {
    return <h3 className={`text-h3-small lg:text-h3 ${styles}`}>{text}</h3>;
  }

  if (level === 4) {
    return <h4 className={`text-h4-small lg:text-h4 ${styles}`}>{text}</h4>;
  }

  return null;
};

const Title = ({
  className,
  level,
  withBlueSymbol,
  symbol,
  noMargin,
  children,
}) => {
  const styles = `heading font-sans font-bold ${
    noMargin ? "" : "mb-6 lg:mb-8"
  } ${className}`;

  const EndSymbol = !withBlueSymbol ? (
    <></>
  ) : (
    <span className="text-primary">{symbol}</span>
  );

  const Text = (
    <>
      {children}
      {EndSymbol}
    </>
  );

  return getTitle(level, styles, Text);
};

Title.propTypes = {
  className: string,
  children: oneOfType([string, object]),
  level: oneOf([1, 2, 3, 4]),
  withBlueSymbol: bool,
  symbol: string,
  noMargin: bool,
};

Title.defaultProps = {
  className: "",
  children: "",
  level: 1,
  withBlueSymbol: false,
  symbol: ".",
  noMargin: false,
};

export default Title;
