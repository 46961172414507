import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | Von Elrod’s Beer Hall and Kitchen",
  pageDescription:
    "Discover how a famous beer hall in Nashville boosted sales in its high-volume restaurant using SpotOn’s full suite of restaurant POS products.",
  title: "Beer, beef, and baseball",
  subTitle: `How Von Elrod’s increased sales in their high-volume beer hall.`,
  businessName: "Von Elrod’s Beer Hall and Kitchen",
  industry: "Restaurants & hospitality, casual dining",
  location: "Nashville, Tennessee",
  products: [
    spotonProducts.RESTAURANT,
    spotonProducts.SERVE,
    spotonProducts.ORDER,
    spotonProducts.RESERVE,
    spotonProducts.LOYALTY,
  ],

  description:
    "Located across from First Tennessee Park in downtown Nashville, Von Elrod’s draws in anyone who loves beer, beef, and baseball. And with SpotOn, general manager Hillary Holmes found a restaurant platform that covers all of their tech needs in one system.",
  quoteData: {
    imgName: "von-elrods-beer-hall-and-kitchen.png",
    quote: `“We’ve been able to deal with rising product costs with a smaller staff, run a leaner team with better spend controls, and use reporting to find out what time of day we’re getting bigger pushes to schedule breaks, later in-and-out times, and really focus on trends rather than just having four culinary team members waiting for guests to come in at 6 o’clock.”`,
    personName: "Hillary Holmes",
    personTitle: "General Manager, Von Elrod’s Beer Hall and Kitchen",
  },
  goal: `Vol Elrod’s was looking for a restaurant POS that would increase efficiency on all fronts—shorter ticket times, lower labor costs, and faster table turns. Also, they wanted to capture data better to improve their operations.`,
  solution: `SpotOn Restaurant with SpotOn Serve handhelds allows Von Elrod’s to run the same floor with fewer servers. And while SpotOn Reserve keeps tables filled and turning, SpotOn’s comprehensive reports give real-time data for better decision making.`,
  solutionReadMore: {
    text: `Read more about Von Elrod’s story.`,
    url: `https://spoton.com/blog/hillary-holmes-retaining-restaurant-talent-with-tech/`,
  },
  results: {
    title: `The results`,
    stats: [
      { title: `5%`, description: `increase in average tip percentages` },
      {
        title: `$14`,
        description: `increase in average guest spend`,
      },
      { title: `3 min.`, description: `shorter check times` },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "Hot pizza after cold ski days",
    imageName: "base-camp-pizza.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-05-09",
    href: "/case-studies/base-camp-pizza",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.CONNECT_AND_SELL_ONLINE,
    ],
  },
  {
    title: "Steak and tech done to perfection",
    imageName: "deblaze-grill.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-04-07",
    href: "/case-studies/deblaze-grill",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.CONNECT_AND_SELL_ONLINE,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
  {
    title: "Boosting traffic to a BBQ business",
    imageName: "chicago-culinary-kitchen.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-05-02",
    href: "/case-studies/chicago-culinary-kitchen",
    goals: [
      resourceGoals.REACH_NEW_CUSTOMERS,
      resourceGoals.BUILD_CUSTOMER_LOYALTY,
      resourceGoals.GET_SMARTER_DATA,
    ],
  },
];
