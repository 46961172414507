import React from "react";
import { string, shape, arrayOf } from "prop-types";
import { isMobile, isMobileOnly } from "react-device-detect";
import Heading from "../../components-v2/Base/HeadingBuilder";
import trophySvg from "../../images/svg/icons/trophy.svg";
import bulbSvg from "../../images/svg/icons/bulb.svg";
import CtaPrimary from "../../components-v2/Base/CtaPrimary";
import Image from "../../components/ImageQuerys/CaseStudiesImages";
import "./style.css";
import { productToUrlMap } from "../../constants";

const CaseStudy = ({
  className,
  title,
  subTitle,
  businessName,
  industry,
  location,
  products,
  description,
  quoteData,
  goal,
  solution,
  solutionReadMore,
  results,
}) => {
  // eslint-disable-next-line react/prop-types
  const Figure = ({ customClass }) => (
    <figure
      className={`${customClass} cs-figure
        w-full
        bg-black text-white rounded-[24px]
      `}
    >
      <Image
        imageName={quoteData.imgName}
        className="rounded-[24px] md:max-h-[356px] 2xl:max-h-full"
      />

      <figcaption className="p-6 lg:p-10">
        <p className="text-p">{quoteData.quote}</p>

        {quoteData.personName && (
          <Heading
            className="text-white mt-14 lg:mt-10"
            level={2}
            injectionType={4}
          >
            {quoteData.personName}
          </Heading>
        )}
        {quoteData.personTitle && (
          <p className="text-[14px] -mt-6 lg:-mt-8">{quoteData.personTitle}</p>
        )}
      </figcaption>
    </figure>
  );

  return (
    <section
      className={`${className} relative
      max-w-1440 px-4 lg:px-10 2xl:px-40
      w-full flex flex-row justify-between
      gap-10 xl:gap-24
    `}
    >
      <div>
        <Heading
          className="max-w-80-percent lg:max-w-100-percent"
          withBlueSymbol
          level={1}
          injectionType={!isMobile ? 1 : 3}
        >
          {title}
        </Heading>

        <p className="text-p lg:text-p-large text-black-300 ">{subTitle}</p>

        <ul className="p-0 mt-10 lg:mt-20">
          <li
            className="flex flex-col md:flex-row py-4 lg:py-6"
            style={{ borderTop: "1px solid #BFC6D0" }}
          >
            <div className="text-black text-[14px] w-full md:max-w-40-percent lg:max-w-30-percent">
              Business Name
            </div>
            <div className="text-black text-[14px] w-full font-bold mt-2 md:mt-0">
              {businessName}
            </div>
          </li>

          <li
            className="flex flex-col md:flex-row py-4 lg:py-6"
            style={{ borderTop: "1px solid #BFC6D0" }}
          >
            <div className="text-black text-[14px] w-full md:max-w-40-percent lg:max-w-30-percent">
              Industry
            </div>
            <div className="text-black text-[14px] w-full font-bold mt-2 md:mt-0">
              {industry}
            </div>
          </li>

          <li
            className="flex flex-col md:flex-row py-4 lg:py-6"
            style={{ borderTop: "1px solid #BFC6D0" }}
          >
            <div className="text-black text-[14px] w-full md:max-w-40-percent lg:max-w-30-percent">
              Location
            </div>
            <div className="text-black text-[14px] w-full font-bold mt-2 md:mt-0">
              {location}
            </div>
          </li>

          <li
            className="flex flex-col md:flex-row py-4 lg:py-6"
            style={{ borderTop: "1px solid #BFC6D0" }}
          >
            <div className="text-black text-[14px] w-full md:max-w-40-percent lg:max-w-30-percent">
              SpotOn
            </div>
            <div className="text-black text-[14px] w-full font-bold mt-2 md:mt-0">
              {products.map((product, idx) =>
                productToUrlMap[product] === "#" ? (
                  <span>
                    {product} {idx < products.length - 1 ? ", " : null}
                  </span>
                ) : (
                  <a
                    key={product}
                    href={productToUrlMap[product]}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {product}
                    {idx < products.length - 1 ? <span>, </span> : null}
                  </a>
                )
              )}
            </div>
          </li>
        </ul>

        <p
          className="text-black-200 text-p-large mt-10 md:mt-20"
          dangerouslySetInnerHTML={{ __html: description }}
        />

        <div className="block md:hidden">
          <Figure customClass="mt-20" />
        </div>

        <div
          className={`
        border-solid border-y-solid border-primary
        py-10 md:py-16 mt-10 md:mt-20
        grid grid-cols-1 lg:grid-cols-2
        gap-x-8 2xl:gap-x-14 gap-y-10
      `}
          style={{
            borderLeft: "none",
            borderRight: "none",
            borderTopWidth: isMobileOnly ? 0 : 1,
            borderBottomWidth: 1,
          }}
        >
          <div>
            <img src={trophySvg} alt="trophy" />
            <Heading
              withBlueSymbol
              level={3}
              injectionType={4}
              className="mt-6"
            >
              The goal
            </Heading>
            <p
              className="text-p mt-6 text-black-300"
              dangerouslySetInnerHTML={{ __html: goal }}
            />
          </div>

          <div>
            <img src={bulbSvg} alt="trophy" />
            <Heading
              withBlueSymbol
              level={3}
              injectionType={4}
              className="mt-6"
            >
              The solution
            </Heading>
            <p className="text-p mt-6 text-black-300">
              {solution}{" "}
              {solutionReadMore && (
                <a
                  className="underline text-primary"
                  href={solutionReadMore.url}
                  target="_blank"
                  rel="noreferrer"
                >
                  {solutionReadMore.text}
                </a>
              )}
            </p>
          </div>
        </div>

        <div className="mt-10 md:mt-20">
          <Heading className="" withBlueSymbol level={3} injectionType={3}>
            {results.title}
          </Heading>

          <div className="grid grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-8">
            {results.stats.map((item) => (
              <div key={item.title}>
                <div className="text-h3 text-black font-bold">{item.title}</div>
                <p className="text-14 text-black-300 mt-4">
                  {item.description}
                </p>
              </div>
            ))}
          </div>

          <CtaPrimary
            className="mt-10 md:mt-12"
            title="Learn more"
            target="/demo"
          />
        </div>
      </div>

      <div className="hidden md:block min-h-[900px] lg:min-w-[447px]">
        <Figure customClass="sticky top-40" />
      </div>
    </section>
  );
};

CaseStudy.propTypes = {
  className: string,
  title: string.isRequired,
  subTitle: string.isRequired,
  businessName: string.isRequired,
  industry: string.isRequired,
  location: string.isRequired,
  products: arrayOf(string).isRequired,
  description: string.isRequired,
  quoteData: shape({
    imgName: string.isRequired,
    quote: string.isRequired,
    personName: string.isRequired,
    personTitle: string.isRequired,
  }).isRequired,
  goal: string,
  solution: string,
  solutionReadMore: shape({
    text: string.isRequired,
    url: string.isRequired,
  }),
  results: shape({
    title: string,
    stats: arrayOf(
      shape({
        title: string,
        description: string,
      })
    ),
  }).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  // relatedSuccessStories: arrayOf(any),
};

CaseStudy.defaultProps = {
  className: "",
  goal: "",
  solution: "",
  solutionReadMore: null,
  // relatedSuccessStories: null,
};

export default CaseStudy;
