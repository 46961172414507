import React from "react";
import { string, arrayOf, any } from "prop-types";
import { isMobile } from "react-device-detect";
import Heading from "../Base/HeadingBuilder";
import ResourceCard from "../ResourcesCards/resource-card";

const RelatedResources = ({ className, title, relatedResources }) => {
  return (
    <section
      className={`${className} max-w-1440 w-full bg-white relative
        px-4 lg:px-10 2xl:px-40
    `}
    >
      <Heading
        className="text-black text-center"
        withBlueSymbol
        level={3}
        injectionType={isMobile ? 3 : 1}
      >
        {title}
      </Heading>

      <div
        className={`w-full 
        flex flex-wrap
        flex-col md:flex-row
        justify-center md:justify-between items-center md:items-start
        gap-y-12 gap-x-4`}
      >
        {relatedResources.map((item) => (
          <ResourceCard {...item} />
        ))}
      </div>
    </section>
  );
};

RelatedResources.propTypes = {
  className: string,
  title: string,
  // eslint-disable-next-line react/forbid-prop-types
  relatedResources: arrayOf(any).isRequired,
};

RelatedResources.defaultProps = {
  className: "",
  title: "Related success stories",
};

export default RelatedResources;
